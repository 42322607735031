import {
	json,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { Link, useLoaderData } from '@remix-run/react'
import { format } from 'date-fns'
import { prisma } from '#app/utils/db.server.js'
import { getUserImgSrc } from '#app/utils/misc.js'

export const meta: MetaFunction = () => [
	{ title: 'Ryan Cunningham' },
	{
		name: 'description',
		content:
			'Exploring what it means to wrestle with faith, hope, and love... and what to do with doubts and questions through all of it.',
	},
]

export async function loader({}: LoaderFunctionArgs) {
	const posts = await prisma.post.findMany({
		select: {
			id: true,
			title: true,
			content: true,
			slug: true,
			author: {
				select: {
					name: true,
					image: {
						select: {
							id: true,
						},
					},
				},
			},
			tags: {
				select: {
					name: true,
					slug: true,
				},
			},
			updatedAt: true,
			publishedAt: true,
		},
		where: {
			author: { username: 'ryan' },
			publishedAt: { not: null },
		},
		orderBy: {
			publishedAt: 'desc',
		},
		take: 5,
	})

	return json({ posts })
}

export default function Index() {
	const { posts } = useLoaderData<typeof loader>()

	return (
		<main className="font-poppins grid h-full items-start justify-center">
			<div className="px-4 py-16">
				<div className="flex max-w-2xl flex-col">
					<h1
						data-heading
						className="mt-8 animate-slide-top text-4xl font-medium text-foreground [animation-delay:0.3s] [animation-fill-mode:backwards] md:text-5xl xl:mt-4 xl:animate-slide-left xl:text-6xl xl:[animation-delay:0.8s] xl:[animation-fill-mode:backwards]"
					>
						Ryan Cunningham
					</h1>
					{posts.length > 0 && (
						<p
							data-paragraph
							className="mb-8 animate-slide-top text-xl/7 text-muted-foreground [animation-delay:0.8s] [animation-fill-mode:backwards] xl:mt-8 xl:animate-slide-left xl:text-xl/10 xl:leading-10 xl:[animation-delay:1s] xl:[animation-fill-mode:backwards]"
						>
							Check out the latest posts here:
						</p>
					)}
					<div className="flex flex-col gap-12">
						{posts.length === 0 && (
							<div className="animate-slide-top py-4 [animation-delay:1s] [animation-fill-mode:backwards] xl:animate-slide-left xl:[animation-delay:1.2s] xl:[animation-fill-mode:backwards]">
								No posts yet...
							</div>
						)}
						{posts.length > 0 &&
							posts.map((post) => (
								<Link
									key={`post-list-entry-${post.id}`}
									to={post.slug ? `/${post.slug}` : `/posts/${post.id}`}
									prefetch="intent"
									className={
										'animate-slide-top [animation-delay:1s] [animation-fill-mode:backwards] xl:animate-slide-left xl:[animation-delay:1.2s] xl:[animation-fill-mode:backwards]'
									}
								>
									<article
										key={post.id}
										className="flex max-w-xl flex-col items-start justify-between"
									>
										<div className="flex items-center gap-x-4 text-xs">
											{post.publishedAt && (
												<time dateTime={post.publishedAt} className="">
													{format(post.publishedAt, 'MMM dd, yyyy')}
												</time>
											)}
											{!!post.tags.length &&
												post.tags.map((tag) => (
													<Link
														to={`/tags/${tag.slug}`}
														key={`post-tag-${tag.slug}`}
														prefetch="intent"
														className="relative z-10 rounded-full px-3 py-1.5 font-medium"
													>
														{tag.name}
													</Link>
												))}
										</div>
										<div className="group relative">
											<h3 className="mt-2 text-lg font-semibold leading-6 text-foreground group-hover:text-muted-foreground">
												<Link
													to={post.slug ? `/${post.slug}` : `/posts/${post.id}`}
												>
													<span className="absolute inset-0" />
													{post.title}
												</Link>
											</h3>
											<p className="mt-4 line-clamp-2 text-sm leading-6 text-muted-foreground">
												{post.content}
											</p>
										</div>
										<div className="relative mt-4 flex items-center gap-x-4">
											<img
												className="h-8 w-8 rounded-full object-cover"
												alt={post.author.name ?? 'Author'}
												src={getUserImgSrc(post.author.image?.id)}
											/>
											<div className="text-sm leading-6">
												<p className="font-semibold text-gray-900">
													{post.author.name}
												</p>
											</div>
										</div>
									</article>
								</Link>
							))}
					</div>
				</div>
			</div>
		</main>
	)
}
